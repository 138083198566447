import React from 'react';
import { useTranslation } from 'react-i18next';
import Home from './workinprogress';

// function Home(){
  
//     const { t } = useTranslation(['translation']);

//     return (
//   <header className="masthead text-white text-center" style={{ backgroundColor: '#273b87' }}>
//     <div className="container d-flex align-items-center flex-column">
//       <h1 className="masthead-heading mb-0">
//         {t("description.title")}
//       </h1>
//       <h2 className='masthead-subheading mb-0'>
//       {t("description.tagline")}
//       </h2>
//       <p className="masthead-subheading font-weight-light mb-0">
//         {t("description.mission")}
//       </p>
//       <p className="masthead-subheading font-weight-light mb-0">
//         {t("description.vision")}
//       </p>
//       <p className="masthead-subheading font-weight-light mb-0">
//         {t("description.summary.part1")}
//       </p>
//       <p className="masthead-subheading font-weight-light mb-0">
//         {t("description.summary.part2")}
//       </p>
//       <a className="btn btn-primary btn-xl" href="#services" style={{ backgroundColor: '#ff7f50', borderColor: '#ff7f50' }}>{t('headers.learnMore')}</a>
//     </div>
//   </header>
// );
// }


export default Home;
